
















import Vue from 'vue'

export default Vue.extend({
  name: 'PanelExploreHelp',
  props: {
    editable: { type: Boolean, default: false },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {},
})
