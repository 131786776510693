















































import Vue from 'vue'

export default Vue.extend({
  components: {},
  name: 'ListTemplate',
  props: {
    fieldItemLabel: { type: String, default: 'item' },
    fieldNameLabel: { type: String, default: 'name' },
    dropDownLabel: { type: String, default: 'input.details.text' },
    itemsSorted: {
      type: Array,
      default: () => [],
    },
    editable: Boolean,
    icon: { type: String, default: 'mdi-dna' },
    navName: { type: String, default: 'explore' },
  },
  data: () => ({
    activeClass: 'primary lighten-1 font-weight-bold',
    activeClassExact: 'primary lighten-2 font-weight-bold',
  }),
  methods: {
    handleChange($event: any) {
      const item = $event
      this.$router.replace({ params: { ...this.$route.params, item } })
      // this.$emit('change', $event)
    },
    handleDelete(index: Number) {
      this.$emit('delete', index)
    },
    textColor(valid: boolean) {
      return !valid ? 'red--text' : ''
    },
  },
  computed: {
    searchableItems() {
      const items = this.itemsSorted.map((i: any, index: Number) => {
        const text = i[this.fieldItemLabel][this.fieldNameLabel]
        return { text: text, value: index }
      })
      return items
    },
    item(): number {
      return Number.parseInt(this.$route.params.item)
    },
  },
  mounted() {},
})
