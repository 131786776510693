var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-content-template',{attrs:{"inner":""},scopedSlots:_vm._u([{key:"left-col",fn:function(){return [_c('list-template',{attrs:{"itemsSorted":_vm.tempInstitutionSorted,"editable":_vm.editable,"dropDownLabel":_vm.dropDownLabel,"icon":"mdi-bank-outline"},on:{"delete":function($event){return _vm.deleteInstitution($event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.list.text'))+": "),_c('v-spacer'),_c('help-button',{attrs:{"active":_vm.help},on:{"action":function($event){return _vm.handleHelp()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('button.showHide.tooltip'))+" "+_vm._s(_vm.$t('button.help.text'))+" ")])]},proxy:true}])})]},proxy:true},{key:"info",fn:function(){return [_c('info-alert',{attrs:{"active":_vm.help},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('institution-explore-help',{attrs:{"editable":_vm.editable}})]},proxy:true}])}),(_vm.editable)?_c('info-alert',{attrs:{"active":_vm.info || _vm.help},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.info.part1'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.info.part2'))+" ")])]},proxy:true}],null,false,3622884344)}):_vm._e()]},proxy:true},(_vm.editable)?{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary",on:{"click":function($event){return _vm.addInstitution()}}},on),[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.new.text'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-bank-plus")])],1)]}}],null,false,2707797321)},[_c('span',[_vm._v(_vm._s(_vm.$t('buildInstitutions.new.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary ml-2",attrs:{"disabled":!_vm.validInstitutions},on:{"click":function($event){return _vm.saveAll()}}},on),[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.saveAll.text'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-content-save")])],1)]}}],null,false,2919151680)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.saveAll.tooltip')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"warning ml-2",on:{"click":function($event){return _vm.resetAll()}}},on),[_vm._v(" "+_vm._s(_vm.$t('buildPanels.resetAll.text'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-reload")])],1)]}}],null,false,507812154)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buildPanels.resetAll.tooltip.part1'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('buildPanels.resetAll.tooltip.part2')))])])]},proxy:true}:null],null,true)})]},proxy:true},{key:"right-col",fn:function(){return [_c('v-card',{staticClass:"mb-2",attrs:{"outlined":""}},[_c('v-card-text',[_c('institution-details',{attrs:{"institution":_vm.getCurrentInstitution(),"editable":_vm.editable,"panels":_vm.panelNames,"show-read-only-panels":_vm.showReadOnlyPanels},on:{"institution-valid":function($event){return _vm.validateInstitutions($event)},"name-changed":_vm.updateTempInstitutions}})],1),(_vm.editable)?_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteInstitution()}}},on),[_vm._v(" "+_vm._s(_vm.$t('buildInstitutions.delete.text'))+" "),_c('v-spacer'),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,3543337507)},[_c('span',[_vm._v(_vm._s(_vm.$t('buildInstitutions.delete.tooltip')))])])],1):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }