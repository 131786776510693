











































































































































import {
  FullGene,
  FusionIntronGene,
  Gene,
  GenePanelDetails,
  ParsedGene,
  ParsedGenes,
  SynonymGene,
} from '@/types/panel-types'
import Vue from 'vue'
import GeneParsedContent from '@/components/GeneParsedContent.vue'
import { mapGetters } from 'vuex'
import { GeneState, ListItem } from '@/types/ui-types'
import GeneEntryTitle from '@/components/GeneEntryTitle.vue'

export default Vue.extend({
  components: { GeneParsedContent, GeneEntryTitle },
  name: 'PanelDetails',
  props: {
    panel: {
      type: Object,
      default: () =>
        new ListItem(new GenePanelDetails('', [], new ParsedGenes(), ''), true),
    },
    institution: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedInstitution: this.institution,
      rawPanels: process.env.BASE_URL + 'raw_panels/',
      geneState: GeneState,
    }
  },
  computed: {
    ...mapGetters({
      chipOutlined: 'getChipOutlined',
    }),
    formattedGenes() {
      const parsedGenes = new ParsedGenes()
      parsedGenes.symbolFoundGenes = this.panel.item.symbolsOnly.map(
        (g: Gene) => new ParsedGene(g, GeneState.SYMBOL)
      )
      parsedGenes.synonymFoundGenes = this.panel.item.synonymsOnly.map(
        (sg: SynonymGene) =>
          new ParsedGene(
            new Gene(sg.synonym),
            GeneState.SYNONYM,
            sg.gene as FullGene
          )
      )
      if (this.panel.item.fusionsOnly) {
        parsedGenes.fusionFoundGenes = this.panel.item.fusionsOnly.map(
          (fg: FusionIntronGene) =>
            new ParsedGene(
              new Gene(fg.originalName),
              GeneState.FUSION,
              fg.gene as FullGene
            )
        )
      } else {
        parsedGenes.fusionFoundGenes = []
      }
      if (this.panel.item.intronsOnly) {
        parsedGenes.intronFoundGenes = this.panel.item.intronsOnly.map(
          (fg: FusionIntronGene) =>
            new ParsedGene(
              new Gene(fg.originalName),
              GeneState.INTRON,
              fg.gene as FullGene
            )
        )
      } else {
        parsedGenes.intronFoundGenes = []
      }
      parsedGenes.invalidGenes = this.panel.item.invalid.map(
        (g: Gene) => new ParsedGene(g, GeneState.INVALID)
      )
      return parsedGenes
    },
    showInvalid(): boolean {
      return this.formattedGenes.invalidGenes.length > 0
    },
    showSynonym(): boolean {
      return this.formattedGenes.synonymFoundGenes.length > 0
    },
    showSymbol(): boolean {
      return this.formattedGenes.symbolFoundGenes.length > 0
    },
    showFusion(): boolean {
      return this.formattedGenes.fusionFoundGenes.length > 0
    },
    showIntron(): boolean {
      return this.formattedGenes.intronFoundGenes.length > 0
    },
    showAny(): boolean {
      return (
        this.showInvalid ||
        this.showSynonym ||
        this.showSymbol ||
        this.showFusion ||
        this.showIntron
      )
    },
  },
  methods: {
    handleNameChange(event: string[]) {
      this.$emit('name-changed', event)
    },
  },
  watch: {},
  mounted() {},
})
